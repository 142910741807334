import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { Fragment, useState } from 'react';
import { Accent, ButtonType, Size } from '../../../globals';
import Button from '../Button/Button';
import './Header.scss';
import NavigationDrawer from './NavigationDrawer/NavigationDrawer';
import SearchBox from './SearchBox/SearchBox';

//..GatsbyImageSharpFluid_noBase64 for no blur up effect
const fetchLogoQuery = graphql`
    query {
        file(relativePath: { eq: "logos/logo.png" }) {
            childImageSharp {
                fluid(maxWidth: 300, maxHeight: 200) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
    }
`;

const Header: React.FC = () => {
    const [isNavDrawerOpen, setNavDrawerOpen] = useState(false);
    const [searchString, setSearchString] = useState('');

    const image = useStaticQuery(fetchLogoQuery);

    const toggleNavDrawer = () => {
        isNavDrawerOpen ? setNavDrawerOpen(false) : setNavDrawerOpen(true);
    };

    const handleSearchValue = (data: any) => {
        setSearchString(data);
    }

    return (
        <Fragment>
            <header className="header">
                <div
                    className="header__hamburger-icon"
                    onClick={() => toggleNavDrawer()}
                >
                    <svg width="20" height="15">
                        <rect fill="#DA2120" width="20" height="3"></rect>
                        <rect fill="#DA2120" y="6" width="20" height="3"></rect>
                        <rect
                            fill="#DA2120"
                            y="12"
                            width="20"
                            height="3"
                        ></rect>
                    </svg>
                </div>
                
                <div className="header__item">
                    <Link to="/">
                        <Img
                            fluid={image.file.childImageSharp.fluid}
                            alt="WikiExplain"
                            className="logo"
                        ></Img>
                    </Link>
                </div>
                <nav className="header__item nav">
                    {/* <div className="nav__item">
                        <Link to="/about/">How WE works</Link>
                    </div> */}
                    {/* <div className="nav__item dropdown">
                        <div className="nav__item__link">
                            <Link to="">Topics</Link>
                            <div className="nav__item__link__icon dropdown">
                                <svg
                                    width="10"
                                    height="17"
                                    viewBox="0 0 10 13"
                                    fill="#2f4858ffgatbsy pl"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        // eslint-disable-next-line max-len
                                        d="M0.978147 6.375H9.01877C9.57502 6.375 9.85315 7.08887 9.4594 7.50723L5.44065 11.7805C5.1969 12.0395 4.80002 12.0395 4.55627 11.7805L0.537522 7.50723C0.143772 7.08887 0.421897 6.375 0.978147 6.375Z"
                                        fill="black"
                                    />
                                </svg>
                            </div>
                        </div>

                        <div className="dropdown__wrapper">
                            <div className="dropdown-content">
                                <div className="dropdown-content__item">
                                    <Link to="/topics/topic-1/">Topic 1</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link to="/topics/topic-2/">Topic 2</Link>
                                </div>
                                <div className="dropdown-content__item">
                                    <Link to="/topics/topic-3/">Topic 3</Link>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </nav>


                <div className="header__item search-nav">
                    <SearchBox size={Size.small} callback={handleSearchValue}/>
                </div>
                <div className="header__item newsletter-nav">
                    <Button
                        accent={Accent.primary}
                        type={ButtonType.linkInternal}
                        onClickLink={`/search?s=${searchString}`}
                        className="newsletter-nav__button"
                        disabled={searchString === ''}
                    >
                        WE Search
                    </Button>
                </div>

                {/* <div className="header__item header__item__secondary">
                    <a href="">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="#55ACEE"
                            viewBox="0 0 24 24"
                        >
                            <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                        </svg>
                    </a>
                </div>
                <div className="header__item header__item__secondary">
                    <a href="">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="#007bb5"
                            viewBox="0 0 24 24"
                        >
                            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                        </svg>
                    </a>
                </div>                <div className="header__item header__item__secondary">
                    <a href="">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="#55ACEE"
                            viewBox="0 0 24 24"
                        >
                            <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                        </svg>
                    </a>
                </div>

                <div className="header__item header__item__secondary">
                    <a href="">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 225 215"
                            width="25"
                            height="25"
                        >
                            <path
                                d="M104.4 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1.1-6.1-4.5-11.1-10.2-11.1zM140.9 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1s-4.5-11.1-10.2-11.1z"
                            />
                            <path
                                fill="#7289DA"
                                // eslint-disable-next-line max-len
                                d="M189.5 20h-134C44.2 20 35 29.2 35 40.6v135.2c0 11.4 9.2 20.6 20.5 20.6h113.4l-5.3-18.5 12.8 11.9 12.1 11.2 21.5 19V40.6c0-11.4-9.2-20.6-20.5-20.6zm-38.6 130.6s-3.6-4.3-6.6-8.1c13.1-3.7 18.1-11.9 18.1-11.9-4.1 2.7-8 4.6-11.5 5.9-5 2.1-9.8 3.5-14.5 4.3-9.6 1.8-18.4 1.3-25.9-.1-5.7-1.1-10.6-2.7-14.7-4.3-2.3-.9-4.8-2-7.3-3.4-.3-.2-.6-.3-.9-.5-.2-.1-.3-.2-.4-.3-1.8-1-2.8-1.7-2.8-1.7s4.8 8 17.5 11.8c-3 3.8-6.7 8.3-6.7 8.3-22.1-.7-30.5-15.2-30.5-15.2 0-32.2 14.4-58.3 14.4-58.3 14.4-10.8 28.1-10.5 28.1-10.5l1 1.2c-18 5.2-26.3 13.1-26.3 13.1s2.2-1.2 5.9-2.9c10.7-4.7 19.2-6 22.7-6.3.6-.1 1.1-.2 1.7-.2 6.1-.8 13-1 20.2-.2 9.5 1.1 19.7 3.9 30.1 9.6 0 0-7.9-7.5-24.9-12.7l1.4-1.6s13.7-.3 28.1 10.5c0 0 14.4 26.1 14.4 58.3 0 0-8.5 14.5-30.6 15.2z"
                            />
                        </svg>
                    </a>
                </div> */}
            </header>
            <NavigationDrawer
                isNavDrawerOpen={isNavDrawerOpen}
                onToggleNavDrawer={toggleNavDrawer}
            />
        </Fragment>
    );
};

export default Header;
